import { HttpClient } from "../HttpClient";
import { isVVV } from "@/helpers/siteIdentifier";
/**
 * * Get Monthly Report
 */
export const GetMonthlyReport = async ({ UserId, Year }) => {
	try {
		const currentDashboard = isVVV() ? 3 : 2;
		const { data } = await HttpClient.get("api/revenues/RevenueReportData", {
			params: {
				UserId,
				Year,
				currentDashboard,
			},
		});
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
