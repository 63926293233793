<template>
  <div class="table-container">
    <MDBDatatable :dataset="dataTable" :loading="loader" />
  </div>
</template>

<script>
import { MDBDatatable } from "mdb-vue-ui-kit";
import { useMonthlyReportStore } from "@/store/monthlyReport";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "@vue/runtime-core";
import { getMonthFullFormat } from "@/helpers/parseDate";
import { addCommaNumbers } from "@/helpers/addCommaNumbers";
export default {
  components: {
    MDBDatatable,
  },
  setup() {
    const monthlyReportStore = useMonthlyReportStore();
    const tableHeaders = computed(() => {
      return [
        {
          label: "Month",
          field: "month",
        },
        {
          label: "Year",
          field: "year",
        },
        {
          label: "Asset Label",
          field: "assetLabel",
        },
        {
          label: "Asset ID",
          field: "assetId",
        },
        {
          label: "Revenue",
          field: "revenue",
        },
      ];
    });
    const dataTable = ref({
      columns: tableHeaders.value,
      rows: [],
    });
    const { monthlyAssetGrossReport, loader } = storeToRefs(monthlyReportStore);
    watch(
      () => monthlyAssetGrossReport.value,
      (newReport) => {
        dataTable.value.rows = newReport.map((data) => ({
          ...data,
          ...{
            month: getMonthFullFormat(data.month),
            revenue: `$${addCommaNumbers(data.revenue.toFixed(2))}`,
          },
        }));
      }
    );
    return {
      tableHeaders,
      monthlyAssetGrossReport,
      getMonthFullFormat,
      addCommaNumbers,
      dataTable,
      loader,
    };
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  border-radius: 4px;
  overflow-x: auto;

  :deep(.table) {
    margin-bottom: 0;

    caption {
      font-size: 1rem;
      padding-left: 20px;
    }
  }
}
</style>